export const environment = {
  production: true,
  country: "brasil",
  countryCode: "BR",
  pageTitle: "(BRASIL) - bConnect",
  toolbarBgColor: "#099244",
  countries: [{ id: "AR", name: "Argentina" }, { id: "BR", name: "Brasil" }, { id: "PY", name: "Paraguai" }, { id: "UY", name: "Uruguai" }],
  situacoes: { "10": "Ativo",
  "40": "Suspenso (Lei 10.8333)",
  "41": "Excluído" },
  funcoes: { "IM": "Importador",
  "EX": "Exportador",
  "CA": "Transportador",
  "FW": "Agente de Carga",
  "WH": "Depositário",
  "TR": "Operador Aeroportuário/Portuário",
  "CB": "Despachante" },
  modalidades: [
      { id: 878, nome: "AEO SECURITY CERTIFICATE" },
      { id: 879, nome: "AEO CONFORMITY CERTIFICATE" },
      { id: 891, nome: "AEO FULL CERTIFICATE" }
    ],
  mensagemOEACadastrada: "OEA cadastrada com sucesso. ID da Transação: ",
  apiURL: "https://bconnect.estaleiro.serpro.gov.br/aeo/",
  xlsKeys: ['MasterData.MasterDataParty.Name', 'MasterData.MasterDataParty.Address.Line', 'MasterData.MasterDataParty.Address.City', 'MasterData.MasterDataParty.Address.CountrySubDivisionCode', 'MasterData.MasterDataParty.Address.PostcodeID', 'MasterData.MasterDataParty.ID', 'MasterData.MasterDataParty.AdditionalDocument.ID', 'MasterData.MasterDataParty.AdditionalDocument.EffectiveDateTime', 'MasterData.MasterDataParty.AdditionalDocument.StatusCode', 'MasterData.MasterDataParty.RoleCode']
};