import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./loader/loader.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderHttpInterceptor } from "./loader.httpinterceptor";

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule],
  exports: [LoaderComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
