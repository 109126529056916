import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  controlStack = 0;
  constructor(private NgxSpinnerService: NgxSpinnerService) {}

  show() {
    this.controlStack++;
    this.NgxSpinnerService.show();
  }
  hide() {
    this.controlStack--;
    if (this.controlStack === 0) {
      this.NgxSpinnerService.hide();
    }
  }
}
