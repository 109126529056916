import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppMaterialModule } from "./material.module";
import localeBr from "@angular/common/locales/pt";
import localeAr from "@angular/common/locales/es-AR";
import localeUy from "@angular/common/locales/es-UY";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { NgxSpinnerModule } from "ngx-spinner";

registerLocaleData(localeBr);
registerLocaleData(localeAr);
registerLocaleData(localeUy);
registerLocaleData(localeEn);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FlexLayoutModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SharedModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
