<header class="mat-elevation-z6">
  <div class="header-flag">
    <img [src]="'assets/icons8-brazil-48.png'" />
  </div>
</header>
<main>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</main>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="large"
  color="blue"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
</ngx-spinner>
