import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: 'operadores',
    loadChildren: () => import('./operador/operador.module').then(m => m.OperadorModule)
  }, {
    path: '',
    redirectTo: '/operadores/certificados',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
